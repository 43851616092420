import { render, staticRenderFns } from "./BusinessPlanLog.vue?vue&type=template&id=4394a8d2&scoped=true"
import script from "./BusinessPlanLog.vue?vue&type=script&lang=js"
export * from "./BusinessPlanLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4394a8d2",
  null
  
)

export default component.exports